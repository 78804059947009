











































































































/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import Vue from "vue";

import Message from "./Message.vue";
import MessageListHeader from "./MessageListHeader.vue";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { notifyError } from "@/components/Notification";
import ReceivedSMS from "@/models/ReceivedSMS";
import { SMS } from "@/models/Sms";
import { getAPI } from "@/api/axios-base";
import moment from "moment";

export default Vue.extend({
  components: { Message, MessageListHeader },
  name: "sms-message-list",
  props: {
    // Contact to filter messages
    contact: Object,
  },
  data() {
    return {
      formValid: false,
      intervalSMS: null,
      loadingSendMessage: false,
      chatHeight: 0,
      contactNumber: "",
      loadingbtw: false,
      smsContact: [],
      smsfilters: [],
      sheduleMessageRequest: 0,
    };
  },
  computed: {
    ...mapGetters("crmSMSModule", [
      "messagesPerPatient",
      "numberMessages",
      "findMessages",
    ]),
    ...mapGetters(["isAdmin", "isSuper", "getMethodCalls"]),
    ...mapState("crmSMSModule", [
      "messages",
      "messagesFilters",
      "loading",
      "contactSelect",
      "numberSelect",
    ]),
    ...mapState(["profile"]),
    // Filter by contact sended from PersonList
    messagesFiltered(): any[] {
      const contacto = this.contactSelect;
      if (
        contacto != null &&
        this.numberSelect != null &&
        this.smsfilters.length != 0
      ) {
        const msg = this.smsfilters.filter(
          (sms: any) => sms.from == contacto.number || sms.to == contacto.number
        );

        return msg;
      }

      if (contacto == null) {
        return [];
      } else {
        const msg = this.smsContact.filter(
          (sms: SMS) => sms.from == contacto.number || sms.to == contacto.number
        );
        return msg;
      }
    },
    contactS(): string {
      return this.contact != null ? this.contact.phone.substr(2) : "";
    },
    delta(): number {
      return this.$vuetify.breakpoint.smAndDown ? 52 : 62;
    },
  },
  watch: {
    contact(val) {
      if (val != undefined || val != null) {
        this.contactNumber = "";
      }
    },
    contactSelect(val: any) {
      if (val != undefined && val != null) {
        this.initRequestSms();

        setTimeout(() => {
          this.scrollToBotton();
          this.readSms();
          (this as any).loadingbtw = false;
        }, 4000);
      } else {
        clearInterval((this as any).intervalSMS);
      }
    },
  },
  methods: {
    ...mapActions("crmSMSModule", [
      "actSendMessage",
      "actListMessages",
      "actReadMessages",
    ]),
    ...mapMutations("crmSMSModule", [
      "mutSetContact",
      "mutLoadingBtw",
      "mutnumberSelect",
    ]),
    async initRequestSms() {
      (this as any).loadingbtw = true;
      if (this.contactSelect != null) {
        if (this.isAdmin || this.isSuper) {
          await (this as any)._smsBtw();

          (this as any).intervalSMS = setInterval((this as any)._smsBtw, 4000);
        } else {
          await (this as any).getsmsFiltered();

          (this as any).intervalSMS = setInterval(
            (this as any).getsmsFiltered,
            4000
          );
        }
      } else {
        clearInterval((this as any).intervalSMS);
      }
    },

    async getsmsFiltered() {
      if (this.contactSelect != null) {
        const numer = this.contactSelect.number;
        if (numer != undefined && numer != null && numer != "") {
          getAPI(`/twiliocloud/getSMS/5000/0`, {
            params: { phoneNumber: numer },
          })
            .then((res) => {
              this.smsContact = res.data.result;
            })
            .catch((error) => {
              let mess = error.response.data.message;

              if (mess.includes("[")) {
                mess = mess.replace("[", "");
                mess = mess.replace("]", "");
              }
              notifyError(error.response.data, `An error occurred: ${mess}`);
            });
        } else {
          this.smsContact = [];
        }
      }
    },

    _smsBtw() {
      if (this.contactSelect != null) {
        const number2 =
          this.numberSelect != null && this.numberSelect != undefined
            ? this.numberSelect
            : this.profile.phone;

        getAPI
          .post("/twiliocloud/getSmsBetween", {
            range: { limit: 5000, offset: 0 },
            phone1: this.contactSelect.number,
            phone2: number2,
          })
          .then((res) => {
            if (number2 == this.profile.phone) {
              this.smsContact = res.data.result;
            } else {
              this.smsfilters = res.data.result;
            }
          })
          .catch((error) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },

    isFirst(index: number, list: any[]) {
      const previous = list[index - 1] || false;
      const next = list[index + 1] || false;
      const current = list[index];

      // Si los vecinos no son igual a mi
      if (
        previous &&
        next &&
        previous.from != current.from &&
        next.from != current.from
      )
        return false;

      // Si antes no hay nada y el enterior no es igual
      if (previous == false && previous.from != current.from) return true;
      // Si antes ahy uno pero no es igual
      else if (previous && previous.from != current.from) return true;

      return false;
    },
    isLast(index: number, list: any[]) {
      const previous = list[index - 1] || false;
      const next = list[index + 1] || false;
      const current = list[index];

      // Si no tiene nada alante y atras false
      if (
        next &&
        previous &&
        next.from != current.from &&
        previous.from != current.from
      )
        return false;

      // Si antes no hay nada y el enterior no es igual
      if (next == false && next.from != current.from) return true;
      // Si antes ahy uno pero no es igual
      else if (next && next.from != current.from) return true;

      return false;
    },
    formatDate(date: string) {
      if (!date) return null;
      const [year, month, data] = date.split("-");
      const day = data.substr(-20, 2);
      return `${month}/${day}/${year}`;
    },
    isMiddle(index: number, list: any[]) {
      const previous = list[index - 1] || false;
      const next = list[index + 1] || false;
      const current = list[index];

      // Solo si tiene anterior y siguiente
      if (
        previous &&
        next &&
        previous.from == current.from &&
        next.from == current.from
      )
        return true;
    },
    setChatHeight() {
      this.chatHeight = this.$vuetify.breakpoint.height;
    },
    sendMessage() {
      this.scrollToBotton();
    },
    /*   sheduleRequestSMS(): void {
      clearInterval((window as any).sheduleMessageRequest);
      (window as any).sheduleMessageRequest = setInterval(() => {
        this.actListMessages();
      }, 5000);
    }, */
    isDiferentMessage(
      index: number,
      messages: ReceivedSMS[]
    ): boolean | undefined {
      if (messages[index - 1]) {
        const day1 = moment(messages[index - 1].createAt).get("day");
        const day2 = moment(messages[index].createAt).get("day");

        if (day1 != day2) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    isSend(message: ReceivedSMS): boolean {
      return message.from === this.profile.phone;
    },

    scrollToBotton() {
      if ((this as any).$refs["scrollable"]) {
        (this as any).$refs["scrollable"].scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    newContact() {
      this.mutnumberSelect(null);
      this.mutSetContact(null);
    },

    readSms() {
      if (this.contactSelect != undefined && this.contactSelect != null) {
        const unread = this.smsContact.filter(
          (ms: SMS) =>
            ms.from == this.contactSelect.number && ms.to == this.profile.phone
        );
        if (unread.length != 0) {
          this.actReadMessages(unread).then(() => {});
        }
      }
    },
  },
  async mounted() {
    this.setChatHeight();
  },

  destroyed() {
    this.newContact();
    this.mutSetContact(null);
  },
});
